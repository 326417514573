import React from 'react'
import Nav from './Nav'

function Skills() {

  return (

    <>
      <Nav />
      <section className="skills-wrapper">
        <div className="skills-content">
          <div className="skills-title">
            <h2>Skills</h2>
            <hr className="hr-divider"></hr>
          </div>
          <div className="skills">
            <div className="skill">
              <i className="devicon-html5-plain icon"></i>
              <p>HTML</p>
            </div>           
            <div className="skill">
            <i className="devicon-css3-plain icon"></i>
              <p>CSS</p>
            </div>           
            <div className="skill">
            <i className="devicon-javascript-plain icon"></i>
              <p>JavaScript</p>
            </div>           
            <div className="skill">
            <i className="devicon-react-original icon"></i>
              <p>React</p>
            </div>           
            <div className="skill">
              <i className="devicon-python-plain icon"></i>
              <p>Python</p>
            </div>           
            <div className="skill">
              <i className="devicon-nodejs-plain icon"></i>
              <p>NodeJS</p>
            </div>           
            <div className="skill">
            <i className="devicon-express-original icon"></i>
              <p>Express</p>
            </div>           
            <div className="skill">
            <i className="devicon-django-plain icon"></i>
              <p>Django</p>
            </div>           
            <div className="skill">
            <i className="devicon-mongodb-plain icon"></i>
              <p>MongoDb</p>
            </div>           
            <div className="skill">
            <i className="devicon-postgresql-plain icon"></i>
              <p>PostgreSQL</p>
            </div>           
            <div className="skill">
              <i className="devicon-sass-original icon"></i>
              <p>SASS</p>
            </div>           
            <div className="skill">
            <i className="devicon-visualstudio-plain icon"></i>
              <p>VSCode</p>
            </div>           
            <div className="skill">
              <i className="devicon-git-plain icon"></i>
              <p>Git</p>
            </div>           
            <div className="skill">
            <i className="devicon-github-original icon"></i>
              <p>GitHub</p>
            </div>           
            <div className="skill">
              <i className="devicon-yarn-plain icon"></i>
              <p>Yarn</p>
            </div>           
            <div className="skill">
            <i className="devicon-npm-original-wordmark icon"></i>
              <p>NPM</p>
            </div>           
            <div className="skill">
              <i className="devicon-flutter-plain icon icon"></i>
              <p>Flutter</p>
            </div>           
            <div className="skill">
              <i className="devicon-chrome-plain icon"></i>
              <p>Chrome Dev Tools</p>
            </div>           
            <div className="skill">
              <i className="devicon-slack-plain icon"></i>
              <p>Slack</p>
            </div>           
            <div className="skill">
              <i className="devicon-trello-plain icon"></i>
              <p>Trello</p>
            </div>                   
          </div>
        </div>
      </section>
    </>
  )


}
export default Skills